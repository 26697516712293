



import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const SuperSmartPlan = () => {


    return (
        <>
            {/* <!--  Package Modal Section --> */}
            <section className="outPkgModalSection NoBanner">
                <div className="container">
                    <div className="sectionHeadings">
                        <h3>● Our SuperSmart Plan</h3>
                        <div className="subHedng">
                            <h2>Check Out Our
                                <br /> SuperSmart Plan
                            </h2>
                            <div className="decoSection">
                                <p>Our SuperSmart Model lists the materials, quantities, and costs needed for a construction
                                    project. It's prepared by a cost consultant or quantity surveyor and is used in the bidding
                                    process.</p>
                                <button className="borderLess">SuperSmart Model Process <img className="img-fluid"
                                    src={require(`../assets/images/home/redirct.svg`).default} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="smartListGridCont">
                        <div className="smrtGrdOuter">
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/AccurateBudgeting.svg`).default} />
                                <h2> Accurate Budgeting</h2>
                                <p>Avoid cost overruns and unexpected expenses.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/ReducedWaste.svg`).default} />
                                <h2> Reduced Waste</h2>
                                <p>Minimize material waste due to accurate quantification.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/OptimizedMateria.svg`).default} />
                                <h2> Optimized Material Procurement</h2>
                                <p>Purchase materials at the best prices.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/EfficientLabor.svg`).default} />
                                <h2> Efficient labor management</h2>
                                <p>Reduce labor costs through efficient scheduling.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/Avoidrework.svg`).default} />
                                <h2> Avoid rework</h2>
                                <p>Minimize rework due to errors or omissions.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/Valueengineering.svg`).default} />
                                <h2> Value engineering</h2>
                                <p>Avoid cost overruns and unexpected expenses.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/ReducedContingencies.svg`).default} />
                                <h2> Reduced contingencies</h2>
                                <p>Minimize contingencies due to accurate planning.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/Lowerfinancingcosts.svg`).default} />
                                <h2> Lower financing costs</h2>
                                <p>Reduce financing costs due to timely completion.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/ReducedOpportunity.svg`).default} />
                                <h2> Reduced opportunity costs</h2>
                                <p>Minimize opportunity costs due to delayed completion.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/WarrantyA.svg`).default} />
                                <h2> Warranty and maintenance savings</h2>
                                <p>Reduce long-term maintenance and warranty costs.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/Energyefficiencysavings.svg`).default} />
                                <h2> Energy efficiency savings</h2>
                                <p>Incorporate energy-efficient features and materials.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/Materialgs.svg`).default} />
                                <h2> Material selection savings</h2>
                                <p>Choose cost-effective materials without compromising quality.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/Constructionmethod.svg`).default} />
                                <h2> Construction method savings</h2>
                                <p>Optimize construction methods for cost savings.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/SiteManagemen.svg`).default} />
                                <h2> Site management savings</h2>
                                <p>Reduce site management costs through efficient planning.</p>
                            </div>
                            <div className="smrtGrdCard">
                                <img className="img-fluid" src={require(`../assets/images/SuperSmartPlanIcon/Disputeresolution .svg`).default} />
                                <h2> Accurate Budgeting</h2>
                                <p>Avoid cost overruns and unexpected expenses.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Get Details Section --> */}
            <section>
                <div className="container">
                    <div className="getlist">
                        <p>By implementing these cost-saving measures, clients can potentially save:</p>
                        <ul>
                            <li>
                                <p>- <strong>5-10% </strong> material costs</p>
                            </li>
                            <li>
                                <p>- <strong>5-15%</strong> on labor costs</p>
                            </li>
                            <li>
                                <p>- <strong>2-5%</strong> on overall project costs</p>
                            </li>
                            <li>
                                <p>- <strong>10-20%</strong> on long-term maintenance and warranty costs</p>
                            </li>
                        </ul>
                        <p>Note: These savings estimates are approximate and may vary depending on the specific project and
                            circumstances.</p>
                    </div>
                </div>
            </section>
            {/* <!-- SubFooter Section --> */}
            <section className="subFooter">
                <div className="container">
                    <div className="subFooterConent">
                        <div className="sbFootContent">
                            <h3>Like What <br />You See?</h3>
                            <p> Reach out to us and we are sure to provide you <br />with the best services.</p>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="sbFootImg">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/cozyBed.png`)} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SuperSmartPlan;