import React, { useEffect, useState } from "react";
import packagePlanImage from '../assets/images/home/TopBanners/PackagePlan.png';
import { Link } from "react-router-dom";

const PackagePlain = () => {
    const [fullName, setFullName] = useState('');
    const [plotArea, setPlotArea] = useState('');
    const [plot_location, setPlotLocation] = useState('');
    const [setback, setSetBack] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [numberOfFloors, setNumberOfFloors] = useState(1);
    const [costing, setCosting] = useState('');
    

    const handleCalculation = async (e) => {
        e.preventDefault();
        console.log("Submitted");
        console.log(numberOfFloors);
        console.log(plotArea);
        console.log(setback);
        let calculatedCost = (plotArea * (100 - setback)) * numberOfFloors * 1939
        setCosting(calculatedCost);
    };

    return (
        <>
            {/* <!-- Inner Banner Section --> */}
            <section className="innerPageMain" style={{  backgroundImage: `url(${packagePlanImage})`}}>

                <div className="container">
                    <div className="topbnnerContent">
                        <div className="InnersectionHeadings">
                            <h3>● Our Pre-set Packages</h3>
                            <h2>Choose What Suits
                                <br /> You Best
                            </h2>
                            <p>We offer three types of Packages namely BuildSimple, BuildSmart <br />and ConstructEasy. And a
                                BOQ Plan</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--  Package Modal Section --> */}
            <section className="outPkgModalSection">
                <div className="container">
                    <div className="sectionHeadings">
                        <h3>● Our Package Plans</h3>
                        <div className="subHedng">
                            <h2>Compare Our
                                <br /> Plans
                            </h2>
                            <div className="decoSection">
                                <p>We offer three types of Packages namely BuildSimple, BuildSmart and ConstructEasy. And a BOQ
                                    Mode of Payment.</p>
                                <button className="borderLess">To Know About SuperSmart Model <img className="img-fluid"
                                    src={require(`../assets/images/home/redirct.svg`).default} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="plnPkgCont">
                        <div className="plnPkgGrid">
                            <div className="pkgHead">
                                <h4>Package 1</h4>
                                <div className="pkGoe">
                                    <p>BuildSimple</p>
                                    <span>1796/sq.ft (Incl.GST)</span>
                                </div>
                            </div>
                            <div className="pkgListing">
                                <ul>
                                    <li>
                                        <p> Design & Drawings</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Structure</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Kitchen</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Bathroom</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Doors & Windows</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Painting</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Flooring</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Electrical</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Plumbing</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Miscellaneous</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Fabrication</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Elevation</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Exclusions & Incidentals</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Payment Structure</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Warranty and Guarantee</p><span>+</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="plnPkgGrid">
                            <div className="pkgHead pkg2">
                                <h4>Package 2</h4>
                                <div className="pkGoe">
                                    <p>BuildSmart</p>
                                    <span>1939/sq.ft (Incl.GST)</span>
                                </div>
                            </div>
                            <div className="pkgListing">
                                <ul>
                                    <li>
                                        <p> Design & Drawings</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Structure</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Kitchen</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Bathroom</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Doors & Windows</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Painting</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Flooring</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Electrical</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Plumbing</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Miscellaneous</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Fabrication</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Elevation</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Exclusions & Incidentals</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Payment Structure</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Warranty and Guarantee</p><span>+</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="plnPkgGrid">
                            <div className="pkgHead pkg3">
                                <h4>Package 3</h4>
                                <div className="pkGoe">
                                    <p>ConstructSimple</p>
                                    <span>2229/sq.ft (Incl.GST)</span>
                                </div>
                            </div>
                            <div className="pkgListing">
                                <ul>
                                    <li>
                                        <p> Design & Drawings</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Structure</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Kitchen</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Bathroom</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Doors & Windows</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Painting</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Flooring</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Electrical</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Plumbing</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Miscellaneous</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Fabrication</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Elevation</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Exclusions & Incidentals</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Payment Structure</p><span>+</span>
                                    </li>
                                    <li>
                                        <p>Warranty and Guarantee</p><span>+</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Get Estimate Section --> */}
            <section>
                <div className="container">
                    {/* <div className="sectionHeadings">
                        <h3>● Cost Estimator</h3>
                        <div className="subHedng">
                            <h2>Get An Estimate Of The
                                <br /> Construction Costs
                            </h2>
                            <div className="decoSection">
                                <p>Fill out the form below to get an estimation. Actual costs may vary depending on your city,
                                    talk to our Expert to get a more accurate pricing.</p>
                            </div>
                        </div>
                    </div>
                    <div className="getEstForm">
                        <form onSubmit={handleCalculation}>
                            <div className="innerGetsEstInp">
                                <div className="inputForm">
                                    <label>Name*</label>
                                    <input
                                        type='text'
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="inputForm">
                                    <label>Plot Area (sq.ft)*</label>
                                    <input
                                        type='number'
                                        value={plotArea}
                                        onChange={(e) => setPlotArea(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="inputForm">
                                    <label>Plot Location</label>
                                    <input
                                        type='text'
                                        value={plot_location}
                                        onChange={(e) => setPlotLocation(e.target.value)}
                                        required
                                        />
                                </div>
                                <div className="inputForm">
                                    <label>Setback % *</label>
                                    <input
                                        type='number'
                                        value={setback}
                                        onChange={(e) => setSetBack(e.target.value)}
                                        required
                                        />
                                </div>
                                <div className="inputForm">
                                    <label>Mobile Number</label>
                                    <input
                                        type='number'
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                        />
                                </div>
                                <div className="inputForm">
                                    <label>No. of Floors</label>
                                    <select placeholder='Floors' value={numberOfFloors} onChange={(e) => setNumberOfFloors(e.target.value)} required>
                                        <option value={1}>Ground</option>
                                        <option value={2}>G+1</option>
                                        <option value={3}>G+2</option>
                                        <option value={4}>G+3</option>
                                        <option value={5}>G+4</option>
                                    </select>
                                </div>
                                <div className="formikBtn">
                                    <button type='submit'>Get Estimate</button>
                                </div>
                                {
                                    costing ? (
                                        <>
                                            <p> Cost Estimate : Rs. {costing}.00</p>
                                        </>
                                    ) : (
                                        <>
                                        </>
                                    )
                                }
                            </div>
                        </form>
                    </div> */}
                    <div className="yourCostEstimate">
                        <h2 className="prHeading">Your Cost Estimate</h2>
                        <div className="estGridCont">
                            <div className="estCard pkg1">
                                <div className="estGrdHead">
                                    <h4>Package 1</h4>
                                    <div className="pkGoe">
                                        <p>BuildSimple</p>
                                        <span>1796/sq.ft (Incl.GST)</span>
                                    </div>
                                </div>
                                <div className="estGrdData">
                                    <ul>
                                        <li>
                                            <h4> BUILT UP COST
                                                <p>1070/sq.ft*1800/sq.ft</p>
                                            </h4>
                                            <span>26,00,000 </span>
                                        </li>
                                        <li>
                                            <h4>projected Cost
                                            </h4>
                                            <span>34,00,000</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="estCard">
                                <div className="estGrdHead pkg2">
                                    <h4>Package 2</h4>
                                    <div className="pkGoe">
                                        <p>BuildSmart</p>
                                        <span>1939/sq.ft (Incl.GST)</span>
                                    </div>
                                </div>
                                <div className="estGrdData">
                                    <ul>
                                        <li>
                                            <h4> BUILT UP COST
                                                <p>1070/sq.ft*1800/sq.ft</p>
                                            </h4>
                                            <span>26,00,000</span>
                                        </li>
                                        <li>
                                            <h4>projected Cost
                                            </h4>
                                            <span>34,00,000</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="estCard">
                                <div className="estGrdHead pkg3">
                                    <h4>Package 3</h4>
                                    <div className="pkGoe">
                                        <p>ConstructSimple</p>
                                        <span>2229/sq.ft (Incl.GST)</span>
                                    </div>
                                </div>
                                <div className="estGrdData">
                                    <ul>
                                        <li>
                                            <h4> BUILT UP COST
                                                <p>1070/sq.ft*1800/sq.ft</p>
                                            </h4>
                                            <span>26,00,000</span>
                                        </li>
                                        <li>
                                            <h4>projected Cost
                                            </h4>
                                            <span>34,00,000</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="estCard">
                                <div className="fullCardEst">
                                    <button>Check Out
                                        SuperSmart Plan <img className="img-fluid"
                                            src={require(`../assets/images/home/redirct.svg`).default} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* < !--SubFooter Section-- > */}
            <section className="subFooter">
                <div className="container">
                    <div className="subFooterConent">
                        <div className="sbFootContent">
                            <h3>Like What <br />You See?</h3>
                            <p> Reach out to us and we are sure to provide you <br />with the best services.</p>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="sbFootImg">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/cozyBed.png`)} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PackagePlain;