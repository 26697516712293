



import React, { useEffect, useState } from "react";
import ourProcessImage from '../assets/images/home/TopBanners/OurProcess.png';
import { Link } from "react-router-dom";


const OurProcess = () => {


    return (
        <>
            {/* <!-- Our Process Section --> */}
            <section className="ourProcesSection" style={{backgroundImage: `url(${ourProcessImage})`}}>
                <div className="container">
                    <div className="ourProcessCont">
                        <div className="plnContainer">
                            <div className="pln-left">
                                <div className="sectionHeadings">
                                    <h3>● Our Process</h3>
                                    <h2>Process for Package & SuperSmart Model
                                        <br />
                                    </h2>
                                    <p>Follow along through our detailed guide explaining the process for our Package Model and SuperSmart Model.</p>
                                </div>
                            </div>
                            <div className="pln-right">
                                <div className="pkg-grids">
                                    <div className="pkg-card">
                                        <h5>PACKAGE MODEL</h5>
                                        <p>We offer three types of Preset Packages. Go through our guide to know more about the process.
                                        </p>
                                        <Link to="/packagesPlans">Know More <img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default}/></Link>
                                    </div>
                                    <div className="pkg-card">
                                        <h5>SUPER SMART MODEL</h5>
                                        <p>A step-by-step process to help you gain insight on how we plan out our SuperSmart projects.</p>
                                        <Link to="/superSmartPlan">Know More <img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default}/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OurProcess;