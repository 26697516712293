import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import CustomModal from '../modal/customModal'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const phoneNumber = "+917996566444";


    return (
        <>
            <header className="header-main">
                <div className="container">
                    <Navbar expand="lg">
                        <div className="nav-content">
                            <div className="navLogo">
                                <Link to="/">
                                    <img className="img-fluid" src={require(`../../assets/images/home/sriLogo.png`)} />
                                    <span>Sristar Construction</span>
                                </Link>
                            </div>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <div className="navMidData" id="navbarNav">
                                    <ul>
                                        <li className="nav-item"><Link to="/">Home</Link></li>
                                        <li className="nav-item navDrop">
                                            <Dropdown>
                                                <Dropdown.Toggle className="navDropDown">
                                                    Cost Calculator
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item><Link to="/packagesPlans">Package Model</Link></Dropdown.Item>
                                                    <Dropdown.Item><Link to="/superSmartPlan">SuperSmart Model</Link></Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown></li>
                                        <li className="nav-item"><Link to="/projectDetails">Projects</Link></li>
                                        <li className="nav-item"><Link to="/ourProcess">Our Process</Link></li>
                                        <li className="nav-item"><Link to="/about">About Us</Link></li>
                                    </ul>
                                </div>
                                <div className="navButton"><button onClick={() => setModalShow(true)}>Build With Us</button></div>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                    <>
                        <CustomModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </>
                </div>
                <div className="whatsappFloating">
                    <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                        <img className="img-fluid" src={require(`../../assets/images/WhatsApp.svg`).default} alt="WhatsApp" />
                    </a>
                </div>
            </header >

        </>
    );
};

export default Header;