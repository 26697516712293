



import React, { useEffect, useState } from "react";
import superSmartProcessImage from '../assets/images/home/TopBanners/SuperSmartProcess.png';
import { Link } from "react-router-dom";
const SuperMart = () => {
     // State to keep track of open/closed sections
     const [openIndex, setOpenIndex] = useState(0);

     // Toggle function to open/close an accordion section
     const toggleAccordion = (index) => {
         setOpenIndex(openIndex === index ? null : index);
     };
    return (
        <>
            {/* <!-- Inner Banner Section --> */}
            <section className="innerPageMain" style={{backgroundImage: `url(${superSmartProcessImage})` }}>

                <div className="container">
                    <div className="topbnnerContent">
                        <div className="InnersectionHeadings">
                            <h3>● SuperSmart Model</h3>
                            <h2>Why Choose our
                                <br /> SuperSmart Model?
                            </h2>
                            <p>Our detailed model will allow you to get a clear idea of how it
                                <br />works and how it will benefit you.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--  Package Modal Section --> */}
            <section className="outPkgModalSection">
                <div className="container">
                    <div className="sectionHeadings">
                        <div className="subHedng">
                            <h2>How it works
                            </h2>
                            <div className="decoSection">
                                <button className="borderLess">View Plans <img className="img-fluid"
                                    src={require(`../assets/images/home/redirct.svg`).default} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="pkg-acrdcontainer">
                        <div className="pkg-row">
                            <div className="pkgAcrd-card bg-darkBlue"  onClick={() => toggleAccordion(0)}>
                                <div className="acrd-head">
                                    <h2>Step 1</h2>
                                    <p>Client's Requirements </p>
                                </div>
                                {openIndex === 0 && (
                                <div className="pkg-acrd-body">
                                    <ul>
                                        <li>
                                            <p>* Design specifications</p>
                                        </li>
                                        <li>
                                            <p>* Materials</p>
                                        </li>
                                        <li>
                                            <p>* Timeline</p>
                                        </li>
                                        <li>
                                            <p>* Budget</p>
                                        </li>
                                    </ul>
                                </div>
                                 )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card"  onClick={() => toggleAccordion(1)}>
                                <div className="acrd-head">
                                    <h2>Step 2</h2>
                                    <p>SriStar Constructions’ Assessment
                                        & SuperSmart Document </p>
                                </div>
                                {openIndex === 1 && (
                                <div className="pkg-acrd-body">
                                    <p>Construction company provides a detailed proposal, including: </p>
                                    <ul>
                                        <li>
                                            <p>* Quantities of materials and labor</p>
                                        </li>
                                        <li>
                                            <p>* Costs associated with each item</p>
                                        </li>
                                        <li>
                                            <p>* Specifications for materials and workmanship</p>
                                        </li>
                                        <li>
                                            <p>* Timeline for completion</p>
                                        </li>
                                    </ul>
                                    <p>Client reviews, asks questions, and negotiates terms</p>
                                </div>
                                )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card bg-dullBlue"  onClick={() => toggleAccordion(2)}>
                                <div className="acrd-head">
                                    <h2>Step 3</h2>
                                    <p>Client's Approval & Contract Agreement</p>
                                </div>
                                {openIndex === 2 && (
                                <div className="pkg-acrd-body">
                                    <p>The client reviews and approves the SuperSmart document</p>
                                    <p>The client and SriStar Constructions enter into a contract agreement</p>

                                </div>
                                )}
                            </div>
                        </div>
                        <div className="pkg-row">
                            <div className="pkgAcrd-card bg-dullBlue"  onClick={() => toggleAccordion(3)}>
                                <div className="acrd-head">
                                    <h2>Step 4</h2>
                                    <p>Project Execution </p>
                                </div>
                                {openIndex === 3 && (
                                <div className="pkg-acrd-body">
                                    <ul>
                                        <li>
                                            <p>* BOQ document </p>
                                        </li>
                                        <li>
                                            <p>* Contract agreement</p>
                                        </li>
                                    </ul>
                                </div>
                                )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card"  onClick={() => toggleAccordion(4)}>
                                <div className="acrd-head">
                                    <h2>Step 5</h2>
                                    <p>Progress Monitoring </p>
                                </div>
                                {openIndex === 4 && (
                                <div className="pkg-acrd-body">
                                    <p>The client and SriStar Constructions monitor progress, ensuring the project stays on
                                        track, and address any Deviations or Issues </p>
                                </div>
                                )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card bg-darkBlue"  onClick={() => toggleAccordion(5)}>
                                <div className="acrd-head">
                                    <h2>Step 6</h2>
                                    <p>Payment Schedule</p>
                                </div>
                                {openIndex === 5 && (
                                <div className="pkg-acrd-body">
                                    <p>
                                        The client makes payments according to the agreed-upon payment schedule, based on: </p>
                                    <ul>
                                        <li>
                                            <p>- Completed work</p>
                                        </li>
                                        <li>
                                            <p>- Milestones</p>
                                        </li>
                                    </ul>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="pkg-row">
                            <div className="pkgAcrd-card bg-dullBlue"  onClick={() => toggleAccordion(6)}>
                                <div className="acrd-head">
                                    <h2>Step 7</h2>
                                    <p>Quality Control </p>
                                </div>
                                {openIndex === 6 && (
                                <div className="pkg-acrd-body">
                                    <p>The SriStar Constructions ensures quality control measures are in place, meeting the
                                        client's Specifications & Standards</p>
                                </div>
                                )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card"  onClick={() => toggleAccordion(7)}>
                                <div className="acrd-head">
                                    <h2>Step 8</h2>
                                    <p>Project Completion & Final Payment </p>
                                </div>
                                {openIndex === 7 && (
                                <div className="pkg-acrd-body">
                                    <p>The project is completed, and the client inspects and accepts the finished work.
                                        The client makes the final payment, and the SriStar Constructions provides any
                                        necessary:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>- Warranties</p>
                                        </li>
                                        <li>
                                            <p>- Support</p>
                                        </li>
                                    </ul>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="lsto-txt">
                        <p>The SuperSmart model ensures transparency, accountability, and clear communication between the client and SriStar Constructions, resulting in a successful project delivery.</p>
                    </div>
                </div>
            </section>
            {/* <!-- Get Details Section --> */}
            <section className="getDtlsSection">
                <div className="container">
                    <div className="getDtlsContainer">
                        <div className="getDltsLeft">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/wow2.png`)} />
                        </div>
                        <div className="getDltsRight">
                            <h5>To get a detailed view of the model, <br />click on the links below</h5>
                            <ul>
                                <li><a href="">Work Model</a></li>
                                <li><a href="">Features</a></li>
                                <li><a href="">Benefits</a></li>
                                <li><a href="">Payment Schedule</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- SubFooter Section --> */}
            <section className="subFooter">
                <div className="container">
                    <div className="subFooterConent">
                        <div className="sbFootContent">
                            <h3>Like What <br />You See?</h3>
                            <p> Reach out to us and we are sure to provide you <br />with the best services.</p>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="sbFootImg">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/cozyBed.png`)} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SuperMart;