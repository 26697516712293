import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ScrollToTop from './components/scrollTop';
import Header from './components/header/header'
import Footer from './components/footer/footer'
import Home from './pages/home';
import About from './pages/aboutUs';
import OurProcess from './pages/ourProcess';
import PackagesPage from './pages/packages';
import PackagesPlans from './pages/packagePlans';
import ProjectDetail from './pages/projectDetail';
import SuperMart from './pages/superMart';
import SuperSmartPlan from './pages/superSmartPlan';

function App() {
  return (
    <>
    {/* <div className='mobileHide'>
      <p>Please use this website in Desktop mode</p>
    </div> */}
    <Router>
      <ScrollToTop />
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About />} />
        <Route path="/ourProcess" element={<OurProcess />} />
        <Route path="/packages" element={<PackagesPage />} />
        <Route path="/packagesPlans" element={<PackagesPlans />} />
        <Route path="/projectDetails" element={<ProjectDetail />} />
        <Route path="/superMartProcess" element={<SuperMart />} />
        <Route path="/superSmartPlan" element={<SuperSmartPlan />} />
      </Routes>
      <Footer />
    </Router>
    </>
  );
}

export default App;
