import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import './customModal.css';

function UserInfoModal(props) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [pincode, setPincode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showToast, setShowToast] = useState(false); // State for toast visibility

  const handleSubmit = async (e) => {
    e.preventDefault();

    const leadData = {
      full_name: fullName.trim(),
      phone_number: phoneNumber.trim(),
      email: email.trim(),
      pincode: pincode.trim(),
    };

    console.log('Data being sent:', leadData);

    try {
      const response = await fetch('https://backend.aslikahani.com/purity/v1/leads/construction/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leadData),
      });

      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
        }

        setShowToast(true);
        props.onHide();
      } else {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        alert(`Failed to send enquiry: ${errorData.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the enquiry.');
    }
  };

  return (
    <>
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
        <div className='userDetailModal'>
          <Modal.Header className='mdlHeading' closeButton>
            <div className='lostHead'>
              <h2>Build Your Dream House <br />with <span>SriStar</span></h2>
              <p>You will get a response as soon as possible. We will <br />explain in detail how we can help you build your <br />dream house.</p>
            </div>
          </Modal.Header>
          <div className='inputOnBord'>
            <form onSubmit={handleSubmit}>
              <div className='inp-Form'>
                <input
                  type='text'
                  placeholder='NAME'
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
              </div>
              <div className='inp-Form'>
                <input
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className='inp-Form'>
                <input
                  type='number'
                  placeholder='Pincode'
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  required
                />
              </div>
              <div className='inp-Form optForm'>
                <input
                  type='number'
                  placeholder='Phone number'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <button type='submit' className='sendQuery'>Send Enquiry</button>
              </form>
          </div>
        </div>
      </Modal>

      {/* Success Toast */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 1050,
          background:'#8BC34A'
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body>Enquiry sent successfully!</Toast.Body>
      </Toast>
    </>
  );
}

export default UserInfoModal;

