



import React, { useEffect, useState } from "react";
import aboutUsBanner from '../assets/images/home/TopBanners/AboutUs.png';
import getInTouchImage from '../assets/images/home/GetInTouch.png';
import { Link } from "react-router-dom";

const AboutUs = () => {


    return (
        <>
            {/* <!-- Inner Banner Section --> */}
            <section className="innerPageMain" style={{ backgroundImage: `url(${aboutUsBanner})` }}>

                <div className="container">
                    <div className="topbnnerContent">
                        <div className="InnersectionHeadings">
                            <h3>● About Us</h3>
                            <h2>Get to know more
                                <br /> about our company
                            </h2>
                            <p>Join us as we guide you through our hassle-free construction process</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Grid ui Section --> */}
            <section className="gridlistCont">
                <div className="container">
                    <div className="grid-container">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="grid-proj-vrView">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="grid-item blue-bg group">
                                                <h2>SRISTAR GROUP</h2>
                                                <p>SriStar Group is a dynamic and diversified company with a strong presence
                                                    across various sectors like construction, gold, media, hotel. We're driven
                                                    by a commitment to quality, integrity, and innovation, with a clear mission
                                                    to provide outstanding value to our customers, partners, and the communities
                                                    we serve.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="grid-item vision">
                                                <h2>OUR VISION <img className="img-fluid"
                                                    src={require(`../assets/images/AboutPageIcons/our_vision.svg`).default} /></h2>
                                                <p>Our vision is to grow into a global leader, admired for our focus on quality,
                                                    innovation, and sustainability. We’re passionate about making a positive
                                                    difference in society and building long-term value for everyone connected
                                                    with us.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="grid-item blue-gradient business">
                                                <h2>OUR BUSINESSES <img className="img-fluid"
                                                    src={require(`../assets/images/AboutPageIcons/our_business.svg`).default} /></h2>
                                                <ul>
                                                    <li>
                                                        <h5>SRISTAR Constructions: </h5>
                                                        <p>Construction and project management services</p>
                                                    </li>
                                                    <li>
                                                        <h5>SRISTAR Gold Company:</h5>
                                                        <p>We sell, buy, rent, release and repledge gold</p>
                                                    </li>
                                                    <li>
                                                        <p>Visit www.stargoldcompany.com</p>
                                                    </li>
                                                    <li>
                                                        <h5>Asli Kahani:</h5>
                                                        <p>Press/media company</p>
                                                    </li>
                                                    <li>
                                                        <p>Visit www.aslikahani.com</p>
                                                    </li>
                                                    <li>
                                                        <h5>Chai Theory:</h5>
                                                        <p>Hospitality venture</p>
                                                    </li>
                                                    <li>
                                                        <p>Visit www.chaitheory.com</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="gridUiCst">
                                                <div className="grid-item history">
                                                    <h2>OUR HISTORY <img className="img-fluid"
                                                        src={require(`../assets/images/AboutPageIcons/our_history.svg`).default} /></h2>
                                                    <p>SriStar Group was founded in 2019 by Srikanth V, driven by a vision to
                                                        build a world-class organization that truly makes a difference in
                                                        society. Over the years, we've expanded and diversified our operations,
                                                        all while staying true to our core values.</p>
                                                </div>
                                                <div className="grid-item blue-bg values">
                                                    <h2>OUR VALUES <img className="img-fluid"
                                                        src={require(`../assets/images/AboutPageIcons/our_values.svg`).default} /></h2>
                                                    <p><strong>Quality:</strong> We strive for excellence in everything we do.
                                                    </p>

                                                    <p><strong>Integrity</strong>: We operate with transparency and honesty.</p>

                                                    <p><strong>Innovation</strong>: We embrace new ideas and technologies.</p>

                                                    <p><strong>Sustainability</strong>: We prioritize environmental and Social
                                                        responsibility.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="gridUiCst">
                                    <div className="grid-item leadership blue-bg">
                                        <h2>OUR LEADERSHIP <img className="img-fluid"
                                            src={require(`../assets/images/AboutPageIcons/our_leadership.svg`).default} /></h2>
                                        <p>Our leadership team consists of experienced professionals with a proven track record
                                            of success. Our team is committed to driving growth, innovation, and excellence
                                            across all our businesses.</p>
                                    </div>
                                    <div className="grid-item get-in-touch blue-bg"
                                        style={{backgroundImage: `url(${getInTouchImage})`}}>
                                        <h2 className="cstmTch">Get in Touch</h2>
                                        <p>Contact us today to learn more about our businesses and how we can work together to
                                            build a better future.</p>
                                        <div>
                                            <p>+91 98450 78059<br />support@sristarconstruction.com</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- More About Section --> */}
            <section className="moreAbt">
                <div className="container">
                    <div className="sectionHeadings">
                        <h3>● SriStar Constructions</h3>
                        <div className="subHedng">
                            <h2>More About Sristar
                                <br /> Constructions
                            </h2>
                            <div className="decoSection">
                                <p>Sristar Constructions, part of SriStar Group, excels in Residential and Commercial Painting
                                    and Waterproofing. Since 2015, we've brought expertise and a seamless customer experience to
                                    the unorganized painting industry. Our commitment to best practices is evident in everything
                                    we do</p>
                            </div>
                        </div>
                    </div>
                    <div className="outLeaderShip">
                        <div className="leftLedrImgGrid">
                            <div className="ldrGrid">
                                <img className="img-fluid" src={require(`../assets/images/home/Srikanth.png`)} />
                                <div className="ldrDtls">
                                    <p>Srikanth V</p>
                                    <span>Visionary</span>
                                </div>
                            </div>
                            <div className="ldrGrid">
                                <img className="img-fluid" src={require(`../assets/images/home/Sendhil.png`)} />
                                <div className="ldrDtls">
                                    <p>Sendhil Kumar</p>
                                    <span>Co-Founder</span>
                                </div>
                            </div>
                            <div className="ldrGrid">
                                <img className="img-fluid" src={require(`../assets/images/home/Srikanth.png`)} />
                                <div className="ldrDtls">
                                    <p>Archanashruthi B V</p>
                                    <span>Architect</span>
                                </div>
                            </div>
                            <div className="ldrGrid">
                                <img className="img-fluid" src={require(`../assets/images/home/Srikanth.png`)} />
                                <div className="ldrDtls">
                                    <p>Sendhil Kumar</p>
                                    <span>Architect</span>
                                </div>
                            </div>
                        </div>
                        <div className="rghtLedrContent">
                            <h4>Our Leadership</h4>
                            <p>Founded by Srikanth V and Sendhil Kumar , SriStar Constructions aim to provide people with high-quality, value-for-money packages that cater to the diverse needs of our clients.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- SubFooter Section --> */}
            <section className="subFooter">
                <div className="container">
                    <div className="subFooterConent">
                        <div className="sbFootContent">
                            <h3>Like What <br />You See?</h3>
                            <p> Reach out to us and we are sure to provide you <br />with the best services.</p>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="sbFootImg">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/cozyBed.png`)} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;