import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {


    return (
        <>
            <footer className="main-footer">
                <div className="container">
                    <div className="footContent">
                        <div className="foot-links">
                            <div className="foot-links-list">
                                <h3>QUICK LINKS</h3>
                                <ul>
                                    <li><Link to="/">Services</Link></li>
                                    <li><Link to="/projectDetails">Our Projects</Link></li>
                                    <li><Link to="/ourProcess">Our Process</Link></li>
                                    <li><Link to="/">Cost Estimation</Link></li>
                                    <li><Link to="/about">About Us</Link></li>
                                </ul>
                            </div>
                            <div className="foot-links-list">
                                <h3>INFORMATION</h3>
                                <ul>
                                    <li><Link to="/">Privacy Policy</Link></li>
                                    <li><Link to="/">FAQs</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="getTouch">
                            <h2>Get in Touch</h2>
                            <div className="toucInfo">
                                <div className="tc-anch">
                                    <a href="tel:+917996566444">+91 7996566444</a>
                                    <a href="mailto:info@sristargroup.com">info@sristargroup.com</a>
                                </div>
                                <div className="socialMedias">
                                    <Link href=""><img className="img-fluid" src={require(`../../assets/images/SocialsIcons/ins.svg`).default} /></Link>
                                    <Link href=""><img className="img-fluid" src={require(`../../assets/images/SocialsIcons/ic_baseline-facebook.svg`).default} /></Link>
                                    <Link href=""><img className="img-fluid" src={require(`../../assets/images/SocialsIcons/prime_twitter.svg`).default} /></Link>
                                </div>
                            </div>
                            <p className="addresFoot">Corp. Off: 280/1, Anniamma Arcade, Sampige Road, 18th Cross Rd, Malleshwaram,
                                Bengaluru,
                                Karnataka 560003</p>
                        </div>
                    </div>
                    <div className="copyRight">
                        <p>Copyright © <Link to="https://sristargroup.com">SriStar Group 2024</Link> , All Rights Reserved.</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;