import React, { useEffect, useState } from "react";
import packageModelProcessImage from '../assets/images/home/TopBanners/PackageModelProcess.png';
import { Link } from "react-router-dom";

const PackagesPage = () => {

    // State to keep track of open/closed sections
    const [openIndex, setOpenIndex] = useState(0);

    // Toggle function to open/close an accordion section
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <>
            {/* <!-- Inner Banner Section --> */}
            <section className="innerPageMain"
                style={{ backgroundImage: `url(${packageModelProcessImage})` }}>

                <div className="container">
                    <div className="topbnnerContent">
                        <div className="InnersectionHeadings">
                            <h3>● Package Model</h3>
                            <h2>Watch your vision
                                <br /> come to life
                            </h2>
                            <p>Join us as we guide you through our hassle-free construction process</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--  Package Modal Section --> */}
            <section className="outPkgModalSection">
                <div className="container">
                    <div className="sectionHeadings">
                        <h3>● Our Package Model</h3>
                        <div className="subHedng">
                            <h2>Walk Through Our
                                <br /> Package Model Process
                            </h2>
                            <div className="decoSection">
                                <p>We offer three types of Package Plans which has been planned out thoroughly to fit your
                                    needs.</p>
                                <button className="borderLess">View Plans <img className="img-fluid"
                                    src={require(`../assets/images/home/redirct.svg`).default} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="pkg-acrdcontainer">
                        <div className="pkg-row">
                            <div className="pkgAcrd-card bg-darkBlue" onClick={() => toggleAccordion(0)}>
                                <div className="acrd-head">
                                    <h2>Step 1</h2>
                                    <p>Client Consultation and Briefing </p>
                                </div>
                                {openIndex === 0 && (
                                <div className="pkg-acrd-body">
                                    <p>Client shares project vision, goals, and requirements Construction company asks
                                        questions, provides guidance, and clarifies expectations
                                    </p>
                                </div>
                                )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card" onClick={() => toggleAccordion(1)}>
                                <div className="acrd-head">
                                    <h2>Step 2</h2>
                                    <p>Proposal and Estimation </p>
                                </div>
                                {openIndex === 1 && (
                                <div className="pkg-acrd-body">
                                    <p>Construction company provides a detailed proposal, including: </p>
                                    <ul>
                                        <li>
                                            <p>* Project scope </p>
                                        </li>
                                        <li>
                                            <p>* Timeline </p>
                                        </li>
                                        <li>
                                            <p>* Budget </p>
                                        </li>
                                        <li>
                                            <p>* Services offered </p>
                                        </li>
                                    </ul>
                                    <p>Client reviews, asks questions, and negotiates terms</p>
                                </div>
                                )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card bg-dullBlue" onClick={() => toggleAccordion(2)}>
                                <div className="acrd-head">
                                    <h2>Step 3</h2>
                                    <p>Contract Signing and Agreement </p>
                                </div>
                                {openIndex === 2 && (
                                <div className="pkg-acrd-body">
                                    <p>Client and construction company sign a contract outlining:</p>
                                    <ul>
                                        <li>
                                            <p>* Project scope </p>
                                        </li>
                                        <li>
                                            <p> * Payment terms </p>
                                        </li>
                                        <li>
                                            <p>* Timeline </p>
                                        </li>
                                        <li>
                                            <p>* Responsibilities </p>
                                        </li>
                                        <li>
                                            <p>* Warranties and guarantees </p>
                                        </li>
                                    </ul>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className="pkg-row">
                            <div className="pkgAcrd-card bg-dullBlue" onClick={() => toggleAccordion(3)}>
                                <div className="acrd-head">
                                    <h2>Step 4</h2>
                                    <p>Design and Planning </p>
                                </div>
                                {openIndex === 3 && (
                                <div className="pkg-acrd-body">
                                    <p>Design and Planning
                                        Construction company creates detailed designs, plans, and specifications
                                        Client reviews, provides feedback, and approves designs
                                    </p>
                                </div>
                                )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card" onClick={() => toggleAccordion(4)}>
                                <div className="acrd-head">
                                    <h2>Step 5</h2>
                                    <p>Construction and Progress Updates </p>
                                </div>
                                {openIndex === 4 && (
                                <div className="pkg-acrd-body">
                                    <p>Design and Planning
                                        Construction company creates detailed designs, plans, and specifications
                                        Client reviews, provides feedback, and approves designs </p>
                                </div>
                                )}
                            </div>
                            <div className="arrowAcrd">
                                <img className="img-fluid" src={require(`../assets/images/home/TopBanners/arrowRgt.svg`).default} />
                            </div>
                            <div className="pkgAcrd-card bg-darkBlue" onClick={() => toggleAccordion(5)}>
                                <div className="acrd-head">
                                    <h2>Step 6</h2>
                                    <p>Final Inspection, Payment, and Handover </p>
                                </div>
                                {openIndex === 5 && (
                                <div className="pkg-acrd-body">
                                    <p>Design and Planning
                                        Construction company creates detailed designs, plans, and specifications
                                        Client reviews, provides feedback, and approves designs </p>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Get Details Section --> */}
            <section className="getDtlsSection">
                <div className="container">
                    <div className="getDtlsContainer">
                        <div className="getDltsLeft">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/wow1.png`)} />
                        </div>
                        <div className="getDltsRight">
                            <h5>To get a detailed view of the model, <br />click on the links below</h5>
                            <ul>
                                <li><a href="">Work Model</a></li>
                                <li><a href="">Features</a></li>
                                <li><a href="">Benefits</a></li>
                                <li><a href="">Payment Schedule</a></li>
                                <li><a href="">Savings</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- SubFooter Section --> */}
            <section className="subFooter">
                <div className="container">
                    <div className="subFooterConent">
                        <div className="sbFootContent">
                            <h3>Like What <br />You See?</h3>
                            <p> Reach out to us and we are sure to provide you <br />with the best services.</p>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="sbFootImg">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/cozyBed.png`)} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PackagesPage;