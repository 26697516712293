



import React, { useEffect, useState } from "react";
import ourProjectsImage from '../assets/images/home/TopBanners/OurProjects.png';
import { Link } from "react-router-dom";
const ProjectDetail = () => {


    return (
        <>
            {/* <!-- Inner Banner Section --> */}
            <section className="innerPageMain" style={{backgroundImage: `url(${ourProjectsImage})`}}>

                <div className="container">
                    <div className="topbnnerContent">
                        <div className="InnersectionHeadings">
                            <h3>● Our Projects</h3>
                            <h2>Transforming Urban
                                <br /> Landscapes
                            </h2>
                            <p>Our expert team ensures every project we take on is built to perfection.
                                <br />From residential to commercial developments, we provide tailored solutions
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- project Details Section --> */}
            <section className="prjDtlsSectiong">
                <div className="container">
                    <div className="projcDltsGrds">
                        <div className="img-PrjGrid">
                            <div className="row">
                                <div className="col-md-7 col-12">
                                    <div className="prjGrdImg frstGrd">
                                        <img className="img-fluid" src={require(`../assets/images/home/site1.jpg`)} />
                                    </div>
                                </div>
                                <div className="col-md-5 col-12">
                                    <div className="prjGrdImg scGrd">
                                        <img className="img-fluid" src={require(`../assets/images/home/site2.jpg`)} />
                                    </div>
                                    <div className="prjGrdImg thGrd">
                                        <img className="img-fluid" src={require(`../assets/images/home/plot.png`)} />
                                        <button>View More</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="customGrdFull">
                            <div className="cs-left">
                                <h4><img className="img-fluid" src={require(`../assets/images/home/location.svg`).default} />Abigere</h4>
                                <p>Mr. Bhaskar</p>
                                <span>Client</span>
                            </div>
                            <div className="cs-prcjs-right">
                                <div className="cs-gridPrjcts">
                                    <div className="cs-prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/land_area.svg`).default} />
                                        <p>600 sq ft</p>
                                        <span>Land Area</span>
                                    </div>
                                    <div className="cs-prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/built_up_area.svg`).default} />
                                        <p>1800 sq ft</p>
                                        <span>Built-Up Area</span>
                                    </div>
                                    <div className="cs-prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/floor.svg`).default} />
                                        <p>G+2</p>
                                        <span>Floor</span>
                                    </div>
                                    <div className="cs-prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/city.svg`).default} />
                                        <p>Bangalore</p>
                                        <span>City</span>
                                    </div>
                                    <div className="cs-prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/budget.svg`).default} />
                                        <p>₹27,00,000</p>
                                        <span>Budget</span>
                                    </div>
                                    <div className="cs-prjctDtls">
                                        <img className="img-fluid" src={require(`../assets/images/ProjectIcons/status.svg`).default} />
                                        <p>Completed</p>
                                        <span>Status</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- Testimonials Section --> */}
            <section className="testimonialsSection">
                <div className="container">
                    <div className="TestimonialsCont">
                        <div className="testiCard">
                            <h2>Testimonials</h2>
                            <div className="testiImg">
                                <img className="img-fluid" src={require(`../assets/images/home/testi.svg`).default} />
                            </div>
                            <p>I, Baskar, proudly serving in the Indian Army, wholeheartedly endorse SriStar Constructions as a
                                trusted partner in realizing your dream home. Their team of expert associates will skillfully
                                translate your vision into reality, sans your physical presence. With unwavering dedication and
                                expertise, SriStar ensures a seamless experience from conception to completion. Entrust them to
                                handle every facet of construction, allowing you to focus on your priorities. SriStar
                                Constructions is committed to delivering exceptional quality, timely completion, and hassle-free
                                services. They successfully transformed my dream into a tangible reality, and I confidently
                                recommend them to do the same for you."</p>
                            <div className="tstiName">
                                <h5>Mr. Bhaskar</h5>
                                <span>Indian Army</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- SubFooter Section --> */}
            <section className="subFooter">
                <div className="container">
                    <div className="subFooterConent">
                        <div className="sbFootContent">
                            <h3>Like What <br />You See?</h3>
                            <p> Reach out to us and we are sure to provide you <br />with the best services.</p>
                            <Link to="/superSmartPlan">Know More<img className="img-fluid" src={require(`../assets/images/home/redirct.svg`).default} /></Link>
                        </div>
                        <div className="sbFootImg">
                            <img className="img-fluid" src={require(`../assets/images/Illustrations/cozyBed.png`)} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProjectDetail;